<template>
  <div class="main-image-preview">
    <div class="main-image-preview__bg" :style="{ backgroundColor: bgColor }" />
    <div class="share-button-container">
      <CloudinaryImage
        v-if="imgLink"
        id="mainImg"
        class="main-image-preview__img"
        alt="image-banner"
        :url="imgLink"
        :width="664"
        :height="664"
        :modifiers="{ gravity: 'face', roundCorner: 8 }"
      />
      <img
        v-else
        id="mainImg"
        class="main-image-preview__img"
        :src="getSrcImage()"
        :width="664"
        :height="664"
        alt="image-banner"
      />
      <a
        v-if="videoUrl"
        class="main-image-preview__video-url"
        :href="videoUrl"
        target="_blank"
      >
        <img src="@/assets/icons/video-play.svg" alt="Play video" />
      </a>
      <ShareButton v-else-if="shareVisible" />
    </div>
  </div>
</template>

<script>
import ShareButton from '~/components/Elements/ShareButton';
import CloudinaryImage from '@/components/Elements/Image';
import { CreatorBackgroundColors, FallbackImageURL } from '@/misc/constants';

export default {
  name: 'MainImagePreview',

  components: {
    ShareButton,
    CloudinaryImage
  },

  props: {
    imgLink: {
      type: String,
      default: null
    },
    imgSrc: {
      type: String,
      default: null
    },
    shareVisible: {
      type: Boolean,
      default: true
    },
    videoUrl: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      bgColor: null
    };
  },

  mounted() {
    const countItems = 8;
    const randomIndex = Math.floor(Math.random() * countItems);
    this.bgColor = CreatorBackgroundColors[randomIndex];
  },

  methods: {
    getSrcImage() {
      return this.imgSrc
        ? require(`@/assets/${this.imgSrc}`)
        : FallbackImageURL;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-image-preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  &__bg,
  &__img {
    width: 332px;
    height: 332px;
  }

  &__bg {
    position: absolute;
    z-index: 1;
    border-radius: 8px;
    transform: rotate(-4deg);
  }

  &__img {
    position: relative;
    z-index: 2;
    border-radius: 8px;
  }

  @media (min-width: $screen-width-xl) {
    &__bg,
    &__img {
      width: 415px;
      height: 415px;
    }
  }

  &__video-url {
    position: absolute;
    cursor: pointer;
    z-index: 4;
    right: 15px;
    bottom: -20px;
    img {
      width: 40px;
    }
  }
}
</style>
