<template>
  <div
    v-if="userAgent && !isInstagramBrowser"
    class="share-button"
    @click="handleShareClick"
  >
    <img src="@/assets/icons/share.svg" alt="share" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { throttle } from 'lodash';
import { WebApiCancelError } from '@/misc/constants';

export default {
  name: 'ShareButton',

  data() {
    return {
      WebApiCancelError
    };
  },

  computed: {
    ...mapState('main', ['userAgent']),
    ...mapGetters({
      isInstagramBrowser: 'main/isInstagramBrowser'
    })
  },

  methods: {
    handleShareClick: throttle(function () {
      const url = window.location.href;
      if (navigator?.share) {
        navigator.share({ url }).catch((err) => {
          if (!err.message.includes(WebApiCancelError)) {
            this.$showToastMessage(err.message, 'Error', 'error');
          }
        });
      } else if (navigator?.clipboard) {
        navigator.clipboard
          .writeText(url)
          .then(() =>
            this.$showToastMessage('Link is copied', 'Success', 'success')
          )
          .catch((err) =>
            this.$showToastMessage(
              `Failed to copied link ${err.message}`,
              'Error',
              'error'
            )
          );
      } else {
        this.$showToastMessage(
          `Sharing function is not supported`,
          'Error',
          'error'
        );
      }
    }, 1500)
  }
};
</script>

<style lang="scss" scoped>
.share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  z-index: 4;
  right: 15px;
  bottom: -20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: $dark-color;
}
</style>
